<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" autocomplete="off">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('MiniErp.MiniErp.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>

          <!-- <nav>
            Cập nhật Mini ERP
            <button nbButton status="danger" hero size="tiny" (click)="removeFormGroup(i)" style="float: right;"
              title="Xoá">
              <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
            </button>
            <button nbButton status="success" hero size="tiny" (click)="onFormReload()" style="float: right;"
              title="Tải lại">
              <nb-icon pack="eva" icon="refresh-outline"></nb-icon>
            </button>
            <button nbButton status="warning" hero size="tiny" (click)="onFormUndo()" [disabled]="!canUndo"
              style="float: right;" title="Hoàn tác">
              <nb-icon pack="eva" icon="undo-outline"></nb-icon>
            </button>
            <button nbButton status="info" hero size="tiny" (click)="goback()" style="float: right;" title="Trở về">
              <nb-icon pack="eva" icon="arrow-back"></nb-icon>
            </button>
          </nav> -->
        </nb-card-header>
        <nb-card-body>
          <div [formGroup]="formItem">
            <div class="row">
              <input type="hidden" formControlName="Code">
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Name'), 'warning')">
                  <label class="label"><span class="valid">Name (*)</span><span class="invalid">Name là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Name" placeholder="Name">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Description'), 'warning')">
                  <label class="label"><span class="valid">Description (*)</span><span class="invalid">Description là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Description" placeholder="Description">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiUrl'), 'warning')">
                  <label class="label"><span class="valid">Api Url (*)</span><span class="invalid">Api Url là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="ApiUrl" placeholder="Api Url">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiVersion'), 'warning')">
                  <label class="label"><span class="valid">Api Version (*)</span><span class="invalid">Api Version là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="ApiVersion" placeholder="Api Version">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Version'), 'warning')">
                  <label class="label"><span class="valid">Version (*)</span><span class="invalid">Version là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="Version" placeholder="Version">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('License'), 'warning')">
                  <label class="label"><span class="valid">License (*)</span><span class="invalid">License là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="License" placeholder="License">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiUser'), 'warning')">
                  <label class="label"><span class="valid">Api User (*)</span><span class="invalid">Api User là bắt buộc (*)</span></label>
                  <input type="text" nbInput fullWidth formControlName="ApiUser" placeholder="Api User">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('ApiPassword'), 'warning')">
                  <label class="label"><span class="valid">Api Password (*)</span><span class="invalid">Api Password là bắt buộc (*)</span></label>
                  <input type="password" nbInput fullWidth formControlName="ApiPassword" placeholder="Api Password" autocomplete="new-password">
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('Enabled'), 'warning')">
                  <label class="label"><span class="valid">Kích hoạt</span><span class="invalid"></span></label>
                  <nb-checkbox formControlName="Enabled" >Kích hoạt</nb-checkbox>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group" [attr.state]="formControlValidate(formItem.get('AutoUpdate'), 'warning')">
                  <label class="label"><span class="valid">Auto update</span><span class="invalid"></span></label>
                  <nb-checkbox formControlName="AutoUpdate" >Auto update</nb-checkbox>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <button nbButton status="warning" (click)="addFormGroup($event)" hero>Thêm</button>
            <!-- <button nbButton status="info" (click)="onPreview(i)" hero>Xem trước</button> -->
            <button nbButton status="success" [disabled]="!form.valid" hero>Lưu</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>
