<nb-card size="giant" class="same-height-chart">
  <nb-tabset>

    <nb-tab tabTitle="CN phải thu">
      <nb-list class="scrollable-container">
        <nb-list-item class="contact" *ngFor="let c of customerList">
          <nb-user [picture]="c.Avatar?.Thumbnail" [name]="c.ObjectName" [title]="c.ObjectName" size="large" style="flex: 8"></nb-user>
          <span class="caption" style="flex: 1;">{{ c.TailDebit | currency:'VND' }}</span>
        </nb-list-item>
      </nb-list>
    </nb-tab>

    <nb-tab tabTitle="CN phải trả">
      <nb-list class="scrollable-container">
        <nb-list-item class="contact" *ngFor="let c of supplierList">
          <nb-user style="flex: 9;" [picture]="c.FeaturePicture?.Thumbnail" [name]="c.ObjectName" [title]="c.ObjectName" size="large"></nb-user>
          <span style="flex: 1;" class="caption">{{ c.TailCredit | currency:'VND' }}</span>
        </nb-list-item>
      </nb-list>
    </nb-tab>

  </nb-tabset>
</nb-card>