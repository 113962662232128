<nb-card class="small-header ag-grid-card" style="overflow: hidden; height: 100%;" [nbSpinner]="loading" [ngStyle]="{width: width, height: height}">
  <nb-card-header>

    <div class="card-header-container size-large" style="display: flex; align-items: baseline;">
      <div class="title item" [ngClass]="{'headtitle-nowrap': true}" style="flex: 1; margin-right: 1rem;">
        <input fullWidth #Search id="posSearchInput" (keydown)="onSearchInputKeydown($event)" (keyup)="onSearchInputKeyup($event)" type="text" nbInput status="success" fieldSize="medium" [placeholder]="searchInputPlaceholder || (stateStack.length > 0 ? ('Tìm trong danh mục:  ' + cstate.title): 'Chọn danh mục trước khi tìm kiếm...')" class="pos-search-input">
      </div>
      <div class="controls item">
        <ngx-action-control-list [nowrap]="true" [list]="actionButtonList">
          <ng-content id="start" select="#start"></ng-content>
          <ng-content id="end" select="#end"></ng-content>
        </ngx-action-control-list>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body style="display: flex; flex-direction: column;">
    <div class="categories-area scrollable-container" [ngStyle]="{maxHeight: (stateStack.length == 0 ? '100%' : '50%')}">
      <div class="row">
        <div class="col-md-3 category-wrap" *ngFor="let cate of cstate?.categories">
          <div class="category" [ngStyle]="{backgroundImage: 'url('+cate.FeaturePicture?.LargeImage+')'}" (click)="chooseCategory(cate)">
            <div class="feature-products">
                <div class="feature-products-wrap" *ngIf="!cate.FeaturePicture">
                  <div  *ngFor="let featureProduct of cate.FeatureProducts" class="feature-product-image" [ngStyle]="{backgroundImage: 'url('+featureProduct.FeaturePicture?.LargeImage+')'}"></div>
                </div>              
              <div class="cate-title">
                {{cate | objecttext}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="products-area" style="flex: 1">
      <cdk-virtual-scroll-viewport #searchListViewport *ngIf="!isHideProductList && stateStack.length > 0" [itemSize]="150" class="scrollable-container" style="height: 100%">
        <div class="pos-search-result-item" *cdkVirtualFor="let item of cstate.searchResults; let i = index;" [ngClass]="{
                      'active': i == searchResultActiveIndex,
                      'not-same-pre': !productList[i-1] || item.Sku != productList[i-1].Sku,
                      'not-same-next': !productList[i+1] || item.Sku != productList[i+1].Sku
                  }" (click)="onChooseProduct(item)">
          <div class="image" style="
                      background-repeat: no-repeat; 
                      background-size: cover;
                      width: 150px; 
                      height: 100%;
                      margin-right: 0.5rem" [style.backgroundImage]="'url('+item.FeaturePicture?.SmallImage+')'"></div>
          <div style="flex: 1;">
            <div style="font-weight: bold;">{{item.text}}</div>
            <div style="margin-top: 0.5rem;">SKU: {{item.Sku}}</div>
            <div>Vị trí: {{item.Container?.WarehouseName}}/{{item.Container?.ShelfName}}/{{item.Container?.FindOrder || item.Container?.ContainerFindOrder}}, Tồn kho: {{ item.Inventory === null && 'đang kiểm tra...' || (item.Inventory | number)}}</div>
            <!-- <div>ĐVT: {{(item.Unit || item.WarehouseUnit) | objecttext}}</div> -->
            <div style="font-weight: bold; font-size: 2rem; line-height: 2rem;">
              Giá:
              <span *ngIf="item.DiscountPercent > 0">
                <span style="color: var(--gray); text-decoration: line-through; font-size: 1rem;">{{item.ListedPrice | currency:'VND'}}</span>
                <span style="color: var(--red); font-size: 1rem;"> -{{item.DiscountPercent | number:'1.0-2'}}%</span> =
              </span>
              <span style="color: var(--danger); font-size: 2rem;">{{item.Price | currency:'VND'}}</span><span style="color: var(--ck-color-base)">/</span>
              <span style="color: var(--orange)">{{(item.Unit || item.WarehouseUnit) | objecttext}}</span>
            </div>
            <!-- <div style="font-weight: bold; font-size: 2rem; line-height: 2rem;">Giá: <span style="color: var(--danger);">{{item.Price | currency:'VND'}}</span><span style="color: var(--ck-color-base)">/</span><span style="color: var(--orange)">{{(item.Unit || item.WarehouseUnit) | objecttext}}</span> -->
            <!-- <span style="font-size: 1.2rem; line-height: 1.2rem;color: var(---ck-color-base)">{{item.PriceOfBaseUnitText}}</span> -->
            <!-- </div> -->
            <div *ngIf="item.DiscountPercent > 0" style="font-weight: bold;">{{item.DiscountDescription}}</div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </nb-card-body>
</nb-card>