<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card *ngFor="let data of this.data; let i=index;" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Phiếu chốt KPI' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area">
                <ngx-print-header></ngx-print-header>
                <div>
                    <div class="print-title">{{ 'Phiếu chốt KPI' | translate:{action: '', definition: ''} | uppercase }}
                    </div>
                    <div class="under-title">{{ data.Code }} - đến ngày {{ data.DateOfEnd | date:'short' }}</div>
                </div>
                <div>
                    <div class="under-line"><span class="text-label">{{'Common.description' | translate | headtitlecase}}</span>: {{renderValue(data.Title)}}</div>
                    <div class="under-line" style="width: 100%; float: left;"><span class="text-label">{{'Nhân viên' | translate | headtitlecase}}</span>: {{renderValue(data.ObjectName)}}</div>
                    <!-- <div class="under-line" style="width: 50%; float: left;"><span class="text-label">{{'Common.name' | translate | headtitlecase}}</span>: {{renderValue(data.Object)}}</div> -->
                </div>
                <br>
                <div>
                    <ngx-collaborator-sales-kpi-award-info [data]="[data]"></ngx-collaborator-sales-kpi-award-info>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Quản lý' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Nhân viên' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="buttons-row">
                <button nbButton hero status="warning" (click)="prepareCopy(data);">
                    <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
                </button>
                <!-- <button nbButton hero status="danger" (click)="approvedConfirm(data);">{{(processMapList[i]?.nextStateLabel || '') | translate | headtitlecase}}<nb-icon pack="eva" icon="skip-forward"></nb-icon></button> -->
                <button *ngFor="let nextState of processMapList[i]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.label | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
                <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
                    <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>