import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { Component, OnDestroy } from "@angular/core";
import { CommonService } from "../../../../services/common.service";
import { ICellRendererParams } from "@ag-grid-community/core";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: 'ag-icon-cell-renderer',
    template: `
    <div style="display: flex; align-items: center;">
        <nb-icon [pack]="pack | async" [icon]="icon | async" [status]="status | async" style="margin-right: 0.5rem"></nb-icon> {{label | async}}
    </div>
    `,
    styles: [/*css*/`
        span {
            line-height: 1rem;
        }
    `],
})
export class AgIconCellRenderer implements ICellRendererAngularComp, OnDestroy {

    icon = new BehaviorSubject<string>('');
    pack = new BehaviorSubject<string>('eva');
    status = new BehaviorSubject<string>('primary');
    label = new BehaviorSubject<string>('');

    constructor(
        public cms: CommonService,
    ) {

    }
    refresh(params: ICellRendererParams): boolean {
        // throw new Error('Method not implemented.');
        if (this.params.onRefresh) {
            this.params.onRefresh(params, this);
        }
        return true;
    }
    public params: any;

    agInit(params: any): void {
        this.params = params;
        if (params.onInit) {
            params.onInit(params, this);
        }
    }

    // btnClickedHandler(event) {
    //     return this.params.clicked(this.params);
    // }

    ngOnDestroy() {
        // no need to remove the button click handler 
        // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
    }
}