import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { AppModule } from '../../../../../../app.module';
import { agMakeCommandColDef } from '../../../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeStateColDef } from '../../../../../../lib/custom-element/ag-list/column-define/state.define';
import { agMakeTextColDef } from '../../../../../../lib/custom-element/ag-list/column-define/text.define';
import { AgGridDataManagerListComponent } from '../../../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ContactModel } from '../../../../../../models/contact.model';
import { PageModel } from '../../../../../../models/page.model';
import { ApiService } from '../../../../../../services/api.service';
import { CommonService } from '../../../../../../services/common.service';
import { CollaboratorService } from '../../../../collaborator.service';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { Model } from '../../../../../../models/model';
import { CollaboratorSalesKpiStrategyFormComponent } from '../kpi-strategy-form/kpi-strategy-form.component';
import { CollaboratorSalesKpiStrategyPrintComponent } from '../kpi-strategy-print/kpi-strategy-print.component';
import { RootServices } from '../../../../../../services/root.services';
import { DataManagerFormComponent } from '../../../../../../lib/data-manager/data-manager-form.component';
import { FormGroup } from '@angular/forms';
import { CollaboratorSalesKpiStrategy } from '../../../../../../models/collaborator.model';
import { agMakeCurrencyColDef } from '../../../../../../lib/custom-element/ag-list/column-define/currency.define';
import { CollaboratorSalesDirectorListComponent } from '../../../../human-resource/sales-director/collaborator-sales-director-list/collaborator-sales-director-list.component';
import { CollaboratorSalesLeaderListComponent } from '../../../../human-resource/sales-leader/collaborator-sales-leader-list/collaborator-sales-leader-list.component';
import { CollaboratorSellerListComponent } from '../../../../human-resource/seller/collaborator-seller-list/collaborator-seller-list.component';
import { CollaboratorSubSalesDirectorListComponent } from '../../../../human-resource/sub-sales-director/collaborator-sub-sales-director-list/collaborator-sub-sales-director-list.component';
import { name } from 'sip.js';


@Component({
  selector: 'ngx-collaborator-sales-kpi-strategy-list',
  templateUrl: './kpi-strategy-list.component.html',
  styleUrls: ['./kpi-strategy-list.component.scss'],
  providers: [CurrencyPipe, DatePipe],
})
export class CollaboratorSalesKpiStrategyListComponent extends AgGridDataManagerListComponent<Model, CollaboratorSalesKpiStrategyFormComponent> implements OnInit {

  componentName: string = 'CollaboratorSalesKpiStrategyListComponent';
  formPath = '/collaborator/kpi-strategy/form';
  apiPath = '/collaborator/kpi/sales/strategies';
  idKey: string[] = ['Code'];
  textKey = 'Name';
  formDialog = CollaboratorSalesKpiStrategyFormComponent;
  printDialog = CollaboratorSalesKpiStrategyPrintComponent;
  currentPage: PageModel;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;
  // @Input() gridHeight = 'calc(100vh - 230px)';

  public static indicatorList = [
    { id: 'REVENUE', text: 'Doanh thu', unit: '₫' },
    { id: 'ORDERAPPROVEDRATIO', text: 'Tỷ lệ chốt đơn', unit: '%' },
  ];
  public static roleList = [
    { id: 'SUBDIRECTOR', text: 'PGĐ', DistributedRole: 'SALESLEADER' },
    { id: 'SALESLEADER', text: 'Sales Leader', DistributedRole: 'SELLER' },
    { id: 'SELLER', text: 'NV Sales' },
  ];

  public static conditionList = [
    { id: 'GE', text: 'Lớn hơn/bằng (>=)', symbol: '>=', },
    { id: 'GT', text: 'Lớn hơn (>)', symbol: '>' },
    { id: 'LE', text: 'Nhỏ hơn/bằng (<=)', symbol: '<=' },
    { id: 'LT', text: 'Nhỏ hơn (<)', symbol: '<' },
    { id: 'EQ', text: 'Bằng (=)', symbol: '=' },
  ];
  public static groupTypeList = [
    { id: 'REQUIRE', text: 'Bắt buộc' },
    { id: 'OVERKPIAWARD', text: 'Thưởng vượt KPI' },
  ];

  public static processingMap;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<CollaboratorSalesKpiStrategyListComponent>,
    public datePipe: DatePipe,
    public collaboratorService: CollaboratorService,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;

    CollaboratorSalesKpiStrategyListComponent.processingMap = {
      "APPROVED": {
        ...AppModule.approvedState,
        nextState: 'NOTJUSTAPPROVED',
        status: 'success',
        nextStates: [
          AppModule.notJustApprodedState
        ],
      },
      "NOTJUSTAPPROVED": {
        ...AppModule.notJustApprodedState,
        nextState: 'APPROVED',
        nextStates: [
          AppModule.approvedState
        ],
      },
    };
  }

  runningState = {
    ...AppModule.approvedState,
    nextState: 'RUNNING',
    outlilne: true,
    status: 'danger',
    label: 'Đang chạy',
    nextStates: [
      { ...AppModule.notJustApprodedState, status: 'warning' },
    ],
  };

  async init() {
    return super.init().then(async state => {

      await this.cms.waitForLanguageLoaded();

      const processingMap = {
        "APPROVED": {
          ...AppModule.approvedState,
          nextState: 'NOTJUSTAPPROVED',
          status: 'success',
          nextStates: [
            AppModule.notJustApprodedState
          ],
        },
        "NOTJUSTAPPROVED": {
          ...AppModule.notJustApprodedState,
          nextState: 'APPROVED',
          nextStates: [
            AppModule.approvedState
          ],
        },
      };

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: '#',
          field: 'Id',
          maxWidth: 100,
          valueGetter: 'node.data.Code',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'ID',
          field: 'Code',
          maxWidth: 150,
          filter: 'agTextColumnFilter',
          // pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          // pinned: 'left',
          width: 350,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Hoạch định cha',
          field: 'Parent',
          // pinned: 'left',
          width: 200,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Kpi chính',
          field: 'LevelDistributedIndicator',
          // pinned: 'left',
          width: 200,
          filter: 'agTextColumnFilter',
          valueGetter: params => params && params.data && CollaboratorSalesKpiStrategyListComponent.indicatorList.find(f => f.id == this.cms.getObjectId(params.data.LevelDistributedIndicator))
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Vai trò',
          field: 'HumanResourcesRole',
          // pinned: 'left',
          width: 150,
          filter: 'agTextColumnFilter',
          valueGetter: params => params && params.data && CollaboratorSalesKpiStrategyListComponent.roleList.find(f => f.id == this.cms.getObjectId(params.data.HumanResourcesRole))
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Nhân lực',
          field: 'HumanResources',
          // pinned: 'left',
          width: 150,
          filter: 'agTextColumnFilter',
          valueGetter: params => params && params.data && params.data.HumanResources?.length
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Mục tiêu',
          field: 'TargetForIndicator',
          // pinned: 'left',
          width: 200,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Đã phân bổ',
          field: 'cxcsdfs',
          // pinned: 'left',
          width: 200,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Đã đạt được',
          field: 'sdfgsdfgsdf',
          // pinned: 'left',
          width: 200,
          filter: 'agTextColumnFilter',
        },
        {
          ...agMakeStateColDef(this.cms, processingMap, item => {
            // this.stateActionConfirm(item, processingMap);
            this.preview([item]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          maxWidth: 155,
          width: 155,
          resizable: false,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, (data) => {
            this.deleteConfirm([data.Code]);
          }, false, [
            {
              name: 'distribute',
              // label: 'Tạo hoạch định con',
              outline: false,
              title: 'Phân bổ',
              status: 'danger',
              icon: 'share-outline',
              appendTo: 'head',
              action: async (params) => {
                this.distribute(params.data);
                return true;
              }
            },
          ]),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['page'] = this.collaboratorService?.currentpage$?.value;
    params['includeHumanResources'] = true;
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: ContactModel[]) => void, onDialogClose?: () => void) {
    // this.cms.openDialog(ContactFormComponent, {
    //   context: {
    //     inputMode: 'dialog',
    //     inputId: ids,
    //     onDialogSave: (newData: ContactModel[]) => {
    //       if (onDialogSave) onDialogSave(newData);
    //     },
    //     onDialogClose: () => {
    //       if (onDialogClose) onDialogClose();
    //     },
    //   },
    // });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
    // this.gridApi.addEventListener('viewportChanged', (event) => {
    //   console.log(event);
    // })
  }

  onFirstBlockLoaded(): boolean {
    const result = super.onFirstBlockLoaded();
    // if (result) {
    //   this.gridApi.sizeColumnsToFit({
    //     columnLimits: [
    //       {
    //         key: 'Id',
    //         minWidth: 100,
    //         maxWidth: 100,
    //       },
    //       {
    //         key: 'State',
    //         minWidth: 155,
    //         maxWidth: 155,
    //       },
    //       {
    //         key: 'Command',
    //         minWidth: 120,
    //         maxWidth: 120,
    //       },
    //     ]
    //   });
    // }
    return result;
  }

  reset(): boolean {
    const rs = super.reset();
    if (rs) {
      this.gridApi.sizeColumnsToFit({
        columnLimits: [
          {
            key: 'Id',
            minWidth: 100,
            maxWidth: 100,
          },
          {
            key: 'State',
            minWidth: 155,
            maxWidth: 155,
          },
          {
            key: 'Command',
            minWidth: 120,
            maxWidth: 120,
          },
        ]
      });
    }
    return rs;
  }

  async refresh(mode?: string): Promise<void> {
    return super.refresh(mode).then(rs => {
      return rs;
    });
  }

  onChangePage(page: PageModel) {
    this.collaboratorService.currentpage$.next(this.cms.getObjectId(page));
    this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
      this.refresh();
    });
  }

  async createMasterKpiStrategy() {
    // Main kpi ?
    // Main kpi value ?
    // Products ?
    // Human Resource Role ?
    // Human Resources ?
    // => Auto generate strategy
  }

  roleEmployeeMap = {
    'DIRECTOR': CollaboratorSalesDirectorListComponent,
    'SUBDIRECTOR': CollaboratorSubSalesDirectorListComponent,
    'SALESLEADER': CollaboratorSalesLeaderListComponent,
    'SELLER': CollaboratorSellerListComponent,
  };

  async distribute(item: Model) {
    if (!this.printDialog) {
      console.log('Print dialog was not set');
      return false;
    };

    const data = await this.apiService.getPromise<CollaboratorSalesKpiStrategy[]>(this.apiPath + '/' + this.makeId(item), {
      includeDetails: true,
      includeProducts: true,
      includeHumanResources: true,
      includeContact: true,
      includeRelativeVouchers: true,
    }).then(rs => rs[0]);

    data.Name = `Hoạch định con của: ${data.Name}`;
    // data.Description = `Hoạch định con của: ${data.Description}`;
    data.TargetForIndicator = data.TargetForIndicator / data.HumanResources.length;
    data.Parent = data.Code as any;
    data.HumanResources = [];
    data.Products.forEach(product => {
      delete product.Id;
      delete product.SystemUuid;
    });

    const humanResourceRole = CollaboratorSalesKpiStrategyListComponent.roleList.find(f => f.id == this.cms.getObjectId(data.HumanResourcesRole));
    if (humanResourceRole && humanResourceRole.DistributedRole) {
      data.HumanResourcesRole = humanResourceRole.DistributedRole;
    }

    // Request human resources
    const humanResources = await new Promise<[]>(async (resolve, reject) => {
      this.cms.showDialog('Phân bổ KPI xuống cấp dưới', 'Bạn hãy chọn nhân lực cấp dưới để bắt đầu phân bổ', [
        {
          label: 'Trở về',
          status: 'basic',
          outline: true,
          action: () => {
            // resolve([]);
          },
        },
        {
          label: 'Chọn nhân lực',
          status: 'primary',
          outline: false,
          action: () => {
            this.cms.openDialog(this.roleEmployeeMap[this.cms.getObjectId(data.HumanResourcesRole)], {
              context: {
                width: '80vw',
                onDialogChoose(chooseItems) {
                  console.log(chooseItems);
                  resolve(chooseItems.map(m => ({
                    Contact: {
                      id: m.Contact,
                      text: m.Name,
                    },
                    Name: m.Name
                  })));
                },
                onDialogClose: () => {
                  resolve([]);
                }
              }
            });
          },
        },
      ], () => {
        resolve([]);
      })
    });

    if (!humanResources || humanResources.length == 0) {
      return Promise.reject('Bạn chưa chọn nhân lực cho hạch định con');
    }

    data.HumanResources = humanResources;

    data.HumanResources.forEach(humanResource => {
      humanResource.TargetForIndicator = data.TargetForIndicator / humanResources.length;
    });

    data.Details.forEach(detail => {
      detail.LevelDistributedValue = detail.LevelDistributedValue / humanResources.length;
      detail.Conditions.forEach(condition => {
        if (condition.Indicator == data.LevelDistributedIndicator) {
          condition.Kpi = condition.Kpi / humanResources.length;
        }
      });
    });

    delete data.Code;

    this.cms.openDialog(this.formDialog, {
      context: {
        inputMode: 'dialog',
        // inputId: [this.makeId(item)],
        data: [data],
        // isDuplicate: true,
        onDialogSave: (newData: Model[]) => {
          // if (onDialogSave) onDialogSave(row);
          // this.onClose && this.onClose(newData[0]);
          // this.onChange && this.onChange(newData[0]);
          this.refresh();
        },
        onDialogClose: () => {
          // if (onDialogClose) onDialogClose();
          // this.refresh();
        },
        onAfterInit(component: CollaboratorSalesKpiStrategyFormComponent) {
          // const formItem = component.array.controls[0] as FormGroup;
          // const title = formItem.get('Description').value;
          // const targetForIndicator = formItem.get('TargetForIndicator').value;
          // const numOfHumanResources = formItem.get('HumanResources').value.length;
          // formItem.get('Description').setValue('Hạch định con của: ') + title;
          // formItem.get('TargetForIndicator').setValue(targetForIndicator / numOfHumanResources);
          // formItem.get('HumanResources').setValue([]);
          // component.gridApiForHumanResources.setRowData([]);
        },
      },
    });
    return false;
  }
}
