import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-minierp-dashboard',
  templateUrl: './minierp-dashboard.component.html',
  styleUrls: ['./minierp-dashboard.component.scss'],
})
export class MinierpDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
