import { Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { CoreConnectionListComponent } from "../core-connection/core-connection-list/core-connection-list.component";
import { CollaboratorAwardListComponent } from "./award/collaborator-award-list/collaborator-award-list.component";
import { CollaboratorPageReportComponent } from "./collaborator-page-report/collaborator-page-report.component";
import { CollaboratorPublisherDashboardComponent } from "./collaborator-publisher-dashboard/collaborator-publisher-dashboard.component";
import { CollaboratorPublisherReportComponent } from "./collaborator-publisher-report/collaborator-publisher-report.component";
import { CollaboratorPublisherSummaryComponent } from "./collaborator-publisher-summary/collaborator-publisher-summary.component";
import { CollaboratorPublisherComponent } from "./collaborator-publisher/collaborator-publisher.component";
import { CollaboratorCommissionPaymentListComponent } from "./commission-payment/collaborator-commission-payment-list/collaborator-commission-payment-list.component";
import { CollaboratorCommissionListComponent } from "./commission/collaborator-commission-list/collaborator-commission-list.component";
import { CollaboratorEducationArticleListComponent } from "./education-article/education-article-list/collaborator-education-article-list.component";
import { CollaboratorOrderFormComponent } from "./order/collaborator-order-form/collaborator-order-form.component";
import { CollaboratorOrderListComponent } from "./order/collaborator-order-list/collaborator-order-list.component";
import { CollaboratorPageListComponent } from "./page/collaborator-page-list/collaborator-page-list.component";
import { CollaboratorSubscriptionPageListComponent } from "./page/collaborator-subscription-page-list/collaborator-subscription-page-list.component";
import { CollaboratorProductListComponent } from "./product/collaborator-product-list/collaborator-product-list.component";
import { CollaboratorSubscriptionProductComponent } from "./product/collaborator-subscription-product/collaborator-subscription-product.component";
import { CollaboratorPublisherListComponent } from "./publisher/collaborator-publisher-list/collaborator-publisher-list.component";
import { CollaboratorRebuyStrategyListComponent } from "./rebuy-strategy/rebuy-strategy-list/collaborator-rebuy-strategy-list.component";
import { CollaboratorDashboardComponent } from "./collaborator-dashboard/collaborator-dashboard.component";
import { CollaboratorCommissionIncurredListComponent } from "./commission-incurred/commission-incurred-list/commission-incurred-list.component";
import { CollaboratorKpiIndicatorListComponent } from "./kpi/publisher-care/kpi-indicator/kpi-indicator-list/kpi-indicator-list.component";
import { CollaboratorKpiGroupListComponent } from "./kpi/publisher-care/kpi-group/kpi-group-list/kpi-group-list.component";
import { CollaboratorEmployeeGroupListComponent } from "./employee-group/employee-group-list/employee-group-list.component";
import { CollaboratorKpiStrategyListComponent } from "./kpi/publisher-care/kpi-strategy/kpi-strategy-list/kpi-strategy-list.component";
import { CollaboratorKpiAwardListComponent } from "./kpi/publisher-care/kpi-award/kpi-award-list/kpi-award-list.component";
import { CollaboratorKpiDistributedContractListComponent } from "./kpi/publisher-care/kpi-distributed-contract/kpi-distributed-contract-list/kpi-distributed-contract-list.component";
import { CollaboratorKpiDashboardComponent } from "./kpi/publisher-care/kpi-dashboard/kpi-dashboard.component";
import { CollaboratorSellerListComponent } from "./human-resource/seller/collaborator-seller-list/collaborator-seller-list.component";
import { CollaboratorSalesLeaderListComponent } from "./human-resource/sales-leader/collaborator-sales-leader-list/collaborator-sales-leader-list.component";
import { CollaboratorPublisherSupporterListComponent } from "./publisher-supporter/collaborator-publisher-supporter-list/collaborator-publisher-supporter-list.component";
import { CollaboratorOpportunityListComponent } from "./opportunity/opportunity-list/opportunity-list.component";
import { CollaboratorOpportunityFormComponent } from "./opportunity/opportunity-form/opportunity-form.component";
import { CollaboratorSalarySlipListComponent } from "./salary-slip/salary-slip-list/salary-slip-list.component";
import { CollaboratorReportSalaryListComponent } from "./report/salary/report-salary-list/report-salary-list.component";
import { CollaboratorBasicPublisherCareCommissionConfigListComponent } from "./strategies/publisher-care-commission-config/basic-publisher-care-commission-config/basic-publisher-care-commission-config-list/basic-publisher-care-commission-config-list.component";
import { CollaboratorAdvancedPublisherCareCommissionConfigListComponent } from "./strategies/publisher-care-commission-config/advanced-publisher-care-commission-config/advanced-publisher-care-commission-config-list/advanced-publisher-care-commission-config-list.component";
import { CollaboratorAddonPublisherCareCommissionConfigListComponent } from "./strategies/publisher-care-commission-config/addon-publisher-care-commission-config/addon-publisher-care-commission-config-list/addon-publisher-care-commission-config-list.component";
import { CollaboratorCustomerListComponent } from "./customer/collaborator-customer-list/collaborator-customer-list.component";
import { CollaboratorSellerMyReportListComponent } from "./report/seller-my-report/seller-my-report-list/seller-my-report-list.component";
import { CollaboratorPublisherCommissionReportListComponent } from "./report/publisher/commission/commission-list/publisher-commission-report-list.component";
import { CollaboratorPublisherListCommissionReportListComponent } from "./report/publisher/list-commission-report/list-commission-report-list/list-commission-report-list.component";
import { CollaboratorPublisherListCommissionReportFormComponent } from "./report/publisher/list-commission-report/list-commission-report-form/list-commission-report-form.component";
import { CollaboratorOpportunityCostStrategyListComponent } from "./strategies/opportunity-cost-strategy/opportunity-cost-strategy-list/opportunity-cost-strategy-list.component";
import { CollaboratorAddonStrategyListComponent } from "./strategies/addon-strategy/addon-strategy-list/collaborator-addon-strategy-list.component";
import { CollaboratorAdvanceStrategyListComponent } from "./strategies/advance-strategy/advance-strategy-list/collaborator-advance-strategy-list.component";
import { CollaboratorBasicStrategyListComponent } from "./strategies/basic-strategy/basic-strategy-list/collaborator-basic-strategy-list.component";
import { CollaboratorAddonSaleCommissionConfigurationListComponent } from "./strategies/sale-commission-configuration/addon-sale-commission-configuration/addon-sale-commission-configuration-list/collaborator-addon-sale-commission-configuration-list.component";
import { CollaboratorAdvancedSaleCommissionConfigurationListComponent } from "./strategies/sale-commission-configuration/advanced-sale-commission-configuration/advanced-sale-commission-configuration-list/collaborator-advanced-sale-commission-configuration-list.component";
import { CollaboratorBasicSaleCommissionConfigurationListComponent } from "./strategies/sale-commission-configuration/basic-sale-commission-configuration/basic-sale-commission-configuration-list/collaborator-basic-sale-commission-configuration-list.component";
import { CollaboratorStrategyCompileRuleListComponent } from "./strategies/strategy-compile-rules/strategy-compile-rule-list/strategy-compile-rule-list.component";
import { CollaboratorSalesKpiAwardListComponent } from "./kpi/sales/kpi-award/kpi-award-list/kpi-award-list.component";
import { CollaboratorSalesKpiDashboardComponent } from "./kpi/sales/kpi-dashboard/kpi-dashboard.component";
import { CollaboratorSalesKpiDistributedContractListComponent } from "./kpi/sales/kpi-distributed-contract/kpi-distributed-contract-list/kpi-distributed-contract-list.component";
import { CollaboratorSalesKpiGroupListComponent } from "./kpi/sales/kpi-group/kpi-group-list/kpi-group-list.component";
import { CollaboratorSalesKpiIndicatorListComponent } from "./kpi/sales/kpi-indicator/kpi-indicator-list/kpi-indicator-list.component";
import { CollaboratorSalesKpiStrategyListComponent } from "./kpi/sales/kpi-strategy/kpi-strategy-list/kpi-strategy-list.component";
import { CollaboratorSalesDirectorListComponent } from "./human-resource/sales-director/collaborator-sales-director-list/collaborator-sales-director-list.component";
import { CollaboratorSubSalesDirectorListComponent } from "./human-resource/sub-sales-director/collaborator-sub-sales-director-list/collaborator-sub-sales-director-list.component";

export const collaboratorRoutes: Routes = [
  {
    path: 'collaborator/page/list',
    canActivate: [AuthGuardService],
    component: CollaboratorPageListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/publisher/list',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/product/list',
    canActivate: [AuthGuardService],
    component: CollaboratorProductListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/page/dashboard',
    canActivate: [AuthGuardService],
    component: CollaboratorDashboardComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'core-connection/list',
    canActivate: [AuthGuardService],
    component: CoreConnectionListComponent,
    data: {
      reuse: true,
    },
  },
  // {
  //   path: 'collaborator/page',
  //   canActivate: [AuthGuardService],
  //   component: CollaboratorPageComponent,
  //   children: [
  //     // {
  //     //   path: '',
  //     //   redirectTo: 'summary',
  //     //   pathMatch: 'full',
  //     // },
  //     // {
  //     //   path: 'summary',
  //     //   component: CollaboratorPageDashboardComponent,
  //     //   data: {
  //     //     reuse: true,
  //     //   },
  //     // },
  //   ]
  // },


  {
    path: 'collaborator/page/report',
    component: CollaboratorPageReportComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/page/publisher/list',
    component: CollaboratorPublisherListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/customer/list',
    component: CollaboratorCustomerListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/page/page-list',
    component: CollaboratorPageListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/page/order/list',
    component: CollaboratorOrderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/page/order/form',
    component: CollaboratorOrderFormComponent,
  },
  {
    path: 'collaborator/page/order/form/:id',
    component: CollaboratorOrderFormComponent,
  },
  {
    path: 'collaborator/page/product/list',
    component: CollaboratorProductListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/page/commission-payment/list',
    component: CollaboratorCommissionPaymentListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/page/commission/list',
    component: CollaboratorCommissionListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/page/award/list',
    component: CollaboratorAwardListComponent,
    data: {
      reuse: true,
    },
  },
  
  {
    path: 'collaborator/education/article/list',
    canActivate: [AuthGuardService],
    component: CollaboratorEducationArticleListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/publisher/dashboard',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherDashboardComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/publisher',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherComponent,
    children: [
      {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full',
      },
      {
        path: 'summary',
        component: CollaboratorPublisherSummaryComponent,
        data: {
          reuse: true,
        },
      },
      {
        path: 'report',
        component: CollaboratorPublisherReportComponent,
        data: {
          reuse: true,
        },
      },
      {
        path: 'subscription-page/list',
        component: CollaboratorSubscriptionPageListComponent,
        data: {
          reuse: true,
        },
      },
      {
        path: 'order/list',
        component: CollaboratorOrderListComponent,
        data: {
          reuse: true,
        },
      },
      {
        path: 'product/list',
        component: CollaboratorSubscriptionProductComponent,
        data: {
          reuse: true,
        },
      },
      {
        path: 'commission-payment/list',
        component: CollaboratorCommissionPaymentListComponent,
        data: {
          reuse: true,
        },
      },
      {
        path: 'commission/list',
        component: CollaboratorCommissionListComponent,
        data: {
          reuse: true,
        },
      },
      {
        path: 'award/list',
        component: CollaboratorAwardListComponent,
        data: {
          reuse: true,
        },
      },
      {
        path: 'award/list',
        component: CollaboratorAwardListComponent,
        data: {
          reuse: true,
        },
      },
    ]
  },
  {
    path: 'collaborator/page-report',
    canActivate: [AuthGuardService],
    component: CollaboratorPageReportComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/publisher-summary',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherSummaryComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/publisher-report',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherReportComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/order/list',
    canActivate: [AuthGuardService],
    component: CollaboratorOrderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/basic-strategy/list',
    canActivate: [AuthGuardService],
    component: CollaboratorBasicStrategyListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/advance-strategy/list',
    canActivate: [AuthGuardService],
    component: CollaboratorAdvanceStrategyListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/add-on-strategy/list',
    canActivate: [AuthGuardService],
    component: CollaboratorAddonStrategyListComponent,
    data: {
      reuse: true,
    },
  },
  //
  {
    path: 'collaborator/basic-sale-commission-configuration/list',
    canActivate: [AuthGuardService],
    component: CollaboratorBasicSaleCommissionConfigurationListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/advanced-sale-commission-configuration/list',
    canActivate: [AuthGuardService],
    component: CollaboratorAdvancedSaleCommissionConfigurationListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/add-on-sale-commission-configuration/list',
    canActivate: [AuthGuardService],
    component: CollaboratorAddonSaleCommissionConfigurationListComponent,
    data: {
      reuse: true,
    },
  },
  //
  {
    path: 'collaborator/human-resource/director/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSalesDirectorListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/human-resource/sub-director/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSubSalesDirectorListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/human-resource/seller/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSellerListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/human-resource/sales-leader/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSalesLeaderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/human-resource/publisher-care/list',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherSupporterListComponent,
    data: {
      reuse: true,
    },
  },
  //
  {
    path: 'collaborator/rebuy-strategy/list',
    canActivate: [AuthGuardService],
    component: CollaboratorRebuyStrategyListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/commission-incurred/list',
    canActivate: [AuthGuardService],
    component: CollaboratorCommissionIncurredListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/strategy-compile-rule/list',
    canActivate: [AuthGuardService],
    component: CollaboratorStrategyCompileRuleListComponent,
    data: {
      reuse: true,
    },
  },

  //KPI
  {
    path: 'collaborator/kpi/indicators',
    canActivate: [AuthGuardService],
    component: CollaboratorKpiIndicatorListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/group/list',
    canActivate: [AuthGuardService],
    component: CollaboratorKpiGroupListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/employee-group/list',
    canActivate: [AuthGuardService],
    component: CollaboratorEmployeeGroupListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/strategy/list',
    canActivate: [AuthGuardService],
    component: CollaboratorKpiStrategyListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/award/list',
    canActivate: [AuthGuardService],
    component: CollaboratorKpiAwardListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/distributed-contract/list',
    canActivate: [AuthGuardService],
    component: CollaboratorKpiDistributedContractListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/dashboard',
    canActivate: [AuthGuardService],
    component: CollaboratorKpiDashboardComponent,
    data: {
      reuse: true,
    },
  },
  
  //Sales KPI
  {
    path: 'collaborator/kpi/sales/indicators',
    canActivate: [AuthGuardService],
    component: CollaboratorSalesKpiIndicatorListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/sales/group/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSalesKpiGroupListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/sales/employee-group/list',
    canActivate: [AuthGuardService],
    component: CollaboratorEmployeeGroupListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/sales/strategy/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSalesKpiStrategyListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/sales/award/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSalesKpiAwardListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/sales/distributed-contract/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSalesKpiDistributedContractListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/kpi/sales/dashboard',
    canActivate: [AuthGuardService],
    component: CollaboratorSalesKpiDashboardComponent,
    data: {
      reuse: true,
    },
  },

  {
    path: 'collaborator/opportunity/list',
    canActivate: [AuthGuardService],
    component: CollaboratorOpportunityListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/opportunity/form',
    component: CollaboratorOpportunityFormComponent,
  },
  {
    path: 'collaborator/opportunity/form/:id',
    component: CollaboratorOpportunityFormComponent,
  },
  {
    path: 'collaborator/salary-slip/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSalarySlipListComponent,
    data: {
      reuse: true,
    },
  },
  // Salary report
  {
    path: 'collaborator/report/salary/list',
    canActivate: [AuthGuardService],
    component: CollaboratorReportSalaryListComponent,
    data: {
      reuse: true,
    },
  },
  // Opportunity cost strategy
  {
    path: 'collaborator/opportunity-cost-strategy/list',
    canActivate: [AuthGuardService],
    component: CollaboratorOpportunityCostStrategyListComponent,
    data: {
      reuse: true,
    },
  },
  // Basic publisher care commission config
  {
    path: 'collaborator/plan/commission-system/publisher-care/basic/list',
    canActivate: [AuthGuardService],
    component: CollaboratorBasicPublisherCareCommissionConfigListComponent,
    data: {
      reuse: true,
    },
  },
  // Advanced publisher care commission config
  {
    path: 'collaborator/plan/commission-system/publisher-care/advanced/list',
    canActivate: [AuthGuardService],
    component: CollaboratorAdvancedPublisherCareCommissionConfigListComponent,
    data: {
      reuse: true,
    },
  },
  // Add-on publisher care commission config
  {
    path: 'collaborator/plan/commission-system/publisher-care/add-on/list',
    canActivate: [AuthGuardService],
    component: CollaboratorAddonPublisherCareCommissionConfigListComponent,
    data: {
      reuse: true,
    },
  },
  // Report
  // + Seller/My Reports
  {
    path: 'collaborator/report/seller/my-report/list',
    canActivate: [AuthGuardService],
    component: CollaboratorSellerMyReportListComponent,
    data: {
      reuse: true,
    },
  },
  // + Publisher/Commission Reports
  {
    path: 'collaborator/report/publisher/commission/list',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherCommissionReportListComponent,
    data: {
      reuse: true,
    },
  },
  // + Publisher/Commission Reports List
  {
    path: 'collaborator/report/publisher/list-commission/list',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherListCommissionReportListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'collaborator/report/publisher/list-commission/form',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherListCommissionReportFormComponent,
  },
  {
    path: 'collaborator/report/publisher/list-commission/form/:id',
    canActivate: [AuthGuardService],
    component: CollaboratorPublisherListCommissionReportFormComponent,
  },
];