import { Component, OnInit } from '@angular/core';
import { ProductUnitModel } from '../../../../models/product.model';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogService, NbThemeService, NbToastrService, NbDialogRef } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { ProductUnitFormComponent } from '../product-unit-form/product-unit-form.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';

@Component({
  selector: 'ngx-product-unit-list',
  templateUrl: './product-unit-list.component.html',
  styleUrls: ['./product-unit-list.component.scss'],
})
export class ProductUnitListComponent extends AgGridDataManagerListComponent<ProductUnitModel, ProductUnitFormComponent> implements OnInit {

  componentName: string = 'ProductUnitListComponent';
  formPath = '/admin-product/unit/form';
  apiPath = '/admin-product/units';
  idKey = ['Code'];
  formDialog = ProductUnitFormComponent;
  
  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public themeService: NbThemeService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public ref: NbDialogRef<ProductUnitListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  editing = {};
  rows = [];

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 180,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          pinned: 'left',
          width: 500,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên đầy đủ',
          field: 'FullName',
          width: 500,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Biểu tượng',
          field: 'Symbol',
          width: 200,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return status;
    });
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<ProductUnitModel, ProductUnitFormComponent>) {
      return params;
  }

  /** Api get funciton */
  // executeGet(params: any, success: (resources: ProductUnitModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: ProductUnitModel[] | HttpErrorResponse) => void) {
  //   // params['includeParent'] = true;
  //   super.executeGet(params, success, error, complete);
  // }

  // getList(callback: (list: ProductUnitModel[]) => void) {
  //   super.getList((rs) => {
  //     // rs.forEach(item => {
  //     //   item.Content = item.Content.substring(0, 256) + '...';
  //     // });
  //     if (callback) callback(rs);
  //   });
  // }

  // /** Implement required */
  // openFormDialplog(ids?: string[], onDialogSave?: (newData: ProductUnitModel[]) => void, onDialogClose?: () => void) {
  //   this.cms.openDialog(ProductUnitFormComponent, {
  //     context: {
  //       inputMode: 'dialog',
  //       inputId: ids,
  //       onDialogSave: (newData: ProductUnitModel[]) => {
  //         if (onDialogSave) onDialogSave(newData);
  //       },
  //       onDialogClose: () => {
  //         if (onDialogClose) onDialogClose();
  //         this.refresh();
  //       },
  //     },
  //   });
  // }

  // /** Go to form */
  // gotoForm(id?: string): false {
  //   this.openFormDialplog(id ? decodeURIComponent(id).split('&') : null);
  //   return false;
  // }

}
