import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { BusinessModel } from '../../../../models/accounting.model';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { CommonService } from '../../../../services/common.service';
import { AccBusinessFormComponent } from '../acc-business-form/acc-business-form.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';
import { agMakeButtonsColDef } from '../../../../lib/custom-element/ag-list/column-define/buttons.define';

@Component({
  selector: 'ngx-acc-business-list',
  templateUrl: './acc-business-list.component.html',
  styleUrls: ['./acc-business-list.component.scss']
})
export class AccBusinessListComponent extends AgGridDataManagerListComponent<BusinessModel, AccBusinessFormComponent> implements OnInit {

  componentName: string = 'AccBusinessListComponent';
  formPath = '/accounting/business/form';
  apiPath = '/accounting/business';
  idKey = ['Code'];
  formDialog = AccBusinessFormComponent;

  reuseDialog = true;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public themeService: NbThemeService,
    public ref: NbDialogRef<AccBusinessListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init() {
    // await this.loadCache();
    return super.init().then(async rs => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Code',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 150,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tên',
          field: 'Name',
          // pinned: 'left',
          width: 400,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Mô tả',
          field: 'Description',
          width: 500,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tài khoản nợ',
          field: 'DebitAccount',
          width: 150,
          pinned: 'right',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Tài khoản có',
          field: 'CreditAccount',
          width: 150,
          pinned: 'right',
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Loại',
          field: 'Type',
          width: 150,
          pinned: 'right',
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false, [
            {
              name: 'copy',
              label: 'Copy',
              status: 'info',
              outline: false,
              icon: 'copy',
              action: async (params, btnConfig) => {
                this.cms.openDialog(AccBusinessFormComponent, {
                  context: {
                    inputMode: 'dialog',
                    inputId: [params?.data?.Code],
                    isDuplicate: true,
                    onDialogSave: (newData: BusinessModel[]) => {
                      this.refresh();
                    },
                    onDialogClose: () => {
                      this.refresh();
                    },
                  },
                });
                return true;
              }
            }
          ]),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return rs;
    });
  }

  // loadListSetting(): SmartTableSetting {
  //   return this.configSetting({
  //     columns: {
  //       Code: {
  //         title: this.cms.translateText('Common.code'),
  //         type: 'string',
  //         width: '10%',
  //       },
  //       Name: {
  //         title: this.cms.translateText('Common.name'),
  //         type: 'string',
  //         width: '15%',
  //         // filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       Description: {
  //         title: this.cms.translateText('Common.description'),
  //         type: 'string',
  //         width: '20%',
  //       },
  //       DebitAccount: {
  //         title: this.cms.translateText('Accounting.debitAccount'),
  //         type: 'string',
  //         width: '15%',
  //       },
  //       CreditAccount: {
  //         title: this.cms.translateText('Accounting.creditAccount'),
  //         type: 'string',
  //         width: '15%',
  //       },
  //       Type: {
  //         title: this.cms.translateText('Common.type'),
  //         type: 'string',
  //         width: '10%',
  //         // filterFunction: (value: string, query: string) => this.cms.smartFilter(value, query),
  //       },
  //       Copy: {
  //         title: 'Copy',
  //         type: 'custom',
  //         width: '5%',
  //         renderComponent: SmartTableButtonComponent,
  //         onComponentInitFunction: (instance: SmartTableButtonComponent) => {
  //           instance.iconPack = 'eva';
  //           instance.icon = 'copy';
  //           // instance.label = this.cms.translateText('Common.copy');
  //           instance.display = true;
  //           instance.status = 'warning';
  //           instance.valueChange.subscribe(value => {
  //             // if (value) {
  //             //   instance.disabled = false;
  //             // } else {
  //             //   instance.disabled = true;
  //             // }
  //           });
  //           instance.click.subscribe(async (row: BusinessModel) => {

  //             this.cms.openDialog(AccBusinessFormComponent, {
  //               context: {
  //                 inputMode: 'dialog',
  //                 inputId: [row.Code],
  //                 isDuplicate: true,
  //                 onDialogSave: (newData: BusinessModel[]) => {
  //                   // if (onDialogSave) onDialogSave(row);
  //                 },
  //                 onDialogClose: () => {
  //                   // if (onDialogClose) onDialogClose();
  //                   this.refresh();
  //                 },
  //               },
  //             });

  //           });
  //         },
  //       },
  //     },
  //   });
  // }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeParent'] = true;
    return params;
  }

}
