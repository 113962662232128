import { ProductKeywordModel } from '../../../../models/product.model';
import { Component, OnInit } from '@angular/core';
import { SmartTableSetting } from '../../../../lib/data-manager/data-manger-list.component';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ProductKeywordFormComponent } from '../product-keyword-form/product-keyword-form.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';

@Component({
  selector: 'ngx-product-keyword-list',
  templateUrl: './product-keyword-list.component.html',
  styleUrls: ['./product-keyword-list.component.scss'],
})
export class ProductKeywordListComponent extends AgGridDataManagerListComponent<ProductKeywordModel, ProductKeywordFormComponent> implements OnInit {

  componentName: string = 'ProductKeywordListComponent';
  formPath = '/admin-product/product-keyword/form';
  apiPath = '/admin-product/keywords';
  idKey = ['Id'];
  formDialog = ProductKeywordFormComponent;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public _http: HttpClient,
    public themeService: NbThemeService,
    public ref: NbDialogRef<ProductKeywordListComponent>,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);
  }

  async init(): Promise<boolean> {
    return super.init().then(async status => {

      await this.cms.waitForLanguageLoaded();
      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
          headerCheckboxSelection: true,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Từ khóa',
          field: 'Keyword',
          width: 1000,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, false),
          headerName: 'Sửa/Xóa',
        },
      ] as ColDef[]);

      return status;
    });
  }


  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  prepareApiParams(params: any, getRowParams?: IGetRowsParams, component?: AgGridDataManagerListComponent<ProductKeywordModel, ProductKeywordFormComponent>) {
      return params;
  }

  // initDataSource() {
  //   const source = super.initDataSource();

  //   // Set DataSource: prepareData
  //   source.prepareData = (data: ProductKeywordModel[]) => {
  //     data.map((product: ProductKeywordModel) => {
  //       return product;
  //     });
  //     return data;
  //   };

  //   // Set DataSource: prepareParams
  //   source.prepareParams = (params: any) => {
  //     params['sort_Id'] = 'desc';
  //     return params;
  //   };

  //   return source;
  // }

  /** Api get funciton */
  // executeGet(params: any, success: (resources: ProductKeywordModel[]) => void, error?: (e: HttpErrorResponse) => void, complete?: (resp: ProductKeywordModel[] | HttpErrorResponse) => void) {
  //   params['includeCategories'] = true;
  //   super.executeGet(params, success, error, complete);
  // }

  // getList(callback: (list: ProductKeywordModel[]) => void) {
  //   super.getList((rs) => {
  //     if (callback) callback(rs);
  //   });
  // }
}

