import { Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { CommercePosDashboardComponent } from "./commerce-pos-dashboard/commerce-pos-dashboard.component";
import { CommercePosOrderListComponent } from "./commerce-pos-order/commerce-pos-order-list/commerce-pos-order-list.component";
import { CommercePosReturnListComponent } from "./commerce-pos-return/commerce-pos-return-list/commerce-pos-return-list.component";
import { CommercePosGuiComponent } from "./gui/commerce-pos-gui/commerce-pos-gui.component";

export const commercePosRoutes: Routes = [
  {
    path: 'commerce-pos/dashboard',
    canActivate: [AuthGuardService],
    component: CommercePosDashboardComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'commerce-pos/gui',
    canActivate: [AuthGuardService],
    component: CommercePosGuiComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'commerce-pos/order/list',
    canActivate: [AuthGuardService],
    component: CommercePosOrderListComponent,
    data: {
      reuse: true,
    },
  },
  {
    path: 'commerce-pos/return/list',
    canActivate: [AuthGuardService],
    component: CommercePosReturnListComponent,
    data: {
      reuse: true,
    },
  },
];