<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card *ngFor="let data of this.data; let i=index" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {index: i}}" [size]="size" [icon]="favicon" [title]="data['Title'] || ('Hoạch định KPIs' | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area">
                <ngx-print-header></ngx-print-header>
                <div>
                    <div class="print-title">{{ 'Hoạch định KPI' | translate:{action: '', definition: ''} | uppercase }}</div>
                    <div class="under-title">{{ data.Code }} - {{('State.'+(data.State | objectid)) | translate | lowercase}}</div>
                </div>
                <!--<div class="under-line">{{'Tên nhóm' | translate | headtitlecase}} : {{data.Name}}</div>
                <div class="under-line">{{'Chỉ số phân bổ vượt cấp' | translate | headtitlecase}} : {{data.LevelDistributedIndicator | objecttext}}</div> -->
                <div>
                    <!-- <div>
                        <table style="width: 100%; border: 1px solid #ccc;" class="print-voucher-detail-table">
                            <tr class="">
                                <th colspan="3">{{'KPI' | translate | headtitlecase}} : {{data.Name}}</th>
                            </tr>
                            <tr class="">
                                <th colspan="3">{{'Chỉ số phân bổ vượt cấp' | translate | headtitlecase}} : {{data.LevelDistributedIndicator | objecttext}}</th>
                            </tr>
                            <tr class="print-voucher-detail-header">
                                <th>{{'Chỉ số' | translate | headtitlecase}}</th>
                                <th class="text-align-right">{{'Giá trị phân bổ' | translate | headtitlecase}}</th>
                                <th class="text-align-right">{{'Tỷ lệ thưởng vượt KPI' | translate | headtitlecase}}</th>
                            </tr>
                            <ng-container *ngFor="let detail of data.Details; let i = index">
                                <tr class="print-voucher-detail-line" style="font-weight: bold;">
                                    <td>[{{detail.Type | objecttext}}] {{detail.Description}}</td>
                                    <td class="text-align-right">{{detail.LevelDistributedValue | number:'1.0-0'}}</td>
                                    <td class="text-align-right">{{detail.AwardRatio | number:'1.0-0'}}%</td>
                                </tr>
                                <tr class="print-voucher-detail-line" *ngFor="let condition of detail.Conditions; let c = index">
                                    <td colspan="3"> + [{{condition.Weight}}%] {{condition.Indicator | objecttext}} {{condition.Condition?.symbol}} {{condition.Kpi | number:'1.0-0'}}{{condition.Indicator?.unit}}</td>
                                </tr>
                            </ng-container>
                        </table>
                        <div class="under-line" style="font-weight: bold;">Danh sách sản phẩm áp dụng</div>
                        <table style="width: 100%; border: 1px solid #ccc;" class="print-voucher-detail-table">
                            <tr class="print-voucher-detail-header">
                                <th>{{'Stt' | translate | headtitlecase}}</th>
                                <th>{{'ID' | translate | headtitlecase}}</th>
                                <th>{{'Sản phẩm' | translate | headtitlecase}}</th>
                                <th>{{'Đơn vị tính' | translate | headtitlecase}}</th>
                            </tr>
                            <ng-container *ngFor="let product of data.Products; let p = index">
                                <tr class="print-voucher-detail-line">
                                    <td>{{p+1}}</td>
                                    <td>{{product.Product | objectid}}</td>
                                    <td>{{product.ProductName}}</td>
                                    <td>{{product.Unit | objecttext}}</td>
                                </tr>
                            </ng-container>
                        </table>
                    </div> -->
                    <div *ngIf="data">
                        <ngx-collaborator-sales-kpi-strategy-info [data]="[data]"></ngx-collaborator-sales-kpi-strategy-info>
                    </div>
                </div>
                <div style="display: flex; flex-wrap: wrap">
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Giám đốc' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                    <div style="text-align: center; flex: 0 0 50%;">
                        <br> {{'Leader' | translate | headtitlecase}}
                        <br><br><br>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer *ngIf="data.RelativeVouchers && data.RelativeVouchers.length > 0" class="print-relative-vouchers">
            <span class="tag" *ngFor="let relationVoucher of data.RelativeVouchers" nbTooltip="{{relationVoucher.type?.text}}: {{relationVoucher | objectstext}}">
                <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.type?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                <nb-icon pack="eva" icon="close-outline" class="close-btn"></nb-icon>
            </span>
        </nb-card-footer>
        <nb-card-footer>
            <div class="buttons-row">
                <button *ngIf="mode === 'print'" nbButton hero status="info" (click)="edit(data);">
                    <nb-icon pack="eva" icon="edit"></nb-icon>{{'Common.edit' | translate | headtitlecase}}
                </button>
                <button nbButton hero status="info" (click)="prepareCopy(data);" style="float: left">
                    <nb-icon pack="eva" icon="copy"></nb-icon>{{'Common.copy' | translate | headtitlecase}}
                </button>

                <!--State process buttons-->
                <button *ngFor="let nextState of processingMap[data.State|objectid]?.nextStates" nbButton hero status="{{nextState.status}}" (click)="stateActionConfirm(data, nextState);">{{nextState.confirmLabel | translate | headtitlecase}}<nb-icon pack="{{nextState.iconPack || 'eva'}}" icon="{{nextState.icon || 'skip-forward'}}"></nb-icon></button>
                <!--End State process buttons-->

                <button *ngIf="!sourceOfDialog || sourceOfDialog === 'form'" nbButton hero status="success" (click)="saveAndClose(data);">
                    <nb-icon pack="eva" icon="save"></nb-icon>{{'Common.saveAndClose' | translate | headtitlecase}}
                </button>
            </div>

        </nb-card-footer>
    </nb-card>
</div>